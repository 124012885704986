import {ChangeDetectionStrategy, Component, ElementRef, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {selectLoadedAssets} from '@appstore/app.reducer';
import {DEVELOPMENT_SKILLS, FRAMEWORK_SKILLS, TOOLS_SKILLS} from '@applications/welcome/data/skills';
import {faDownload} from '@fortawesome/free-solid-svg-icons/faDownload';
import {faPhone} from '@fortawesome/free-solid-svg-icons/faPhone';
import {faMailBulk} from '@fortawesome/free-solid-svg-icons/faMailBulk';

@Component({
  selector: 'app-welcome',
  template: `
    <div class="app-welcome">
      <div class="app-welcome-left">
        <h3>Pages</h3>
        <ul>
          <li class="intro-anchor active"
              (click)="scrollTo('intro')">⚫ Intro
          </li>
          <li class="story-anchor"
              (click)="scrollTo('story')">⚫ My Story
          </li>
          <li class="education-anchor"
              (click)="scrollTo('education')">⚫ Education
          </li>
          <li class="works-anchor"
              (click)="scrollTo('works')">⚫ Works
          </li>
          <li class="skills-anchor"
              (click)="scrollTo('skills')">⚫ My skills
          </li>
          <li class="more-anchor"
              (click)="scrollTo('more')">⚫ View more
          </li>
        </ul>
        <app-link id="downloadCV"
                  [icon]="faDownload"
                  [link]="'assets/CV-2022.pdf'">
          Download Resume
        </app-link>
      </div>
      <div class="app-welcome-right" (scroll)="onScroll($event)" #welcomeRight>
        <div id="intro">
          <h1>Welcome to My World</h1>
          <img id="about01"
               class="image"
               [src]="'assets//' + (loadedAssets$ | async)?.about01?.path"/>
          <span id="text1">
            Meticulous web developer with over <b>3 years of Front End </b> experience and passion for responsive <b>website design</b>.
            Implementer new creative website approach which increased website traffic by 30+%. I have extensive knowledge and experience with <b>Angular </b>.
            also open to development in <b>Game Developer</b> and am determined on this path with projects. While I am working, I work on <b>Freelance</b> projects simultaneously.
          </span>
          <p class="full-row"><b>Contact me:</b></p>
          <app-link [icon]="faMail"
                    [link]="'mailto:SanderYaz@gmail.com'">SanderYaz@gmail.com
          </app-link>
          <app-link [icon]="faPhone"
                    [link]="'tel:+905396392900'">+90 539 639 29 00
          </app-link>
        </div>
        <div id="story">
          <h2>My story</h2>
          <span id="text2">
           <span id="text2">
            My love for computer programming started at <b>4</b> with my first personal PC. I've started with Tomb Raider, and then moved to Silent Hill. The love for <b>video games</b> made me discover the world of programming. <b>Game Maker Studio</b> was the firsts tool I've used for game programming.<br>
            After some years of experiments, I've decided to studying coding, learning Python and C, and creating my first programs.<br><br>
            The school itself gives me a lot of fun and experience with programming, and meeting people with hitchhiking at the University of Mugla University.<br><br>
             In the University, I've started working as <b>Web Fullstack Developer</b>, using <b>Angular 7+, Typescript, Django and SASS</b> Also complete 2 internship and many projects.<br><br>
            Here I've decided to focus on creativity, and I've started to specialize as Frontend Developer, learning new things about Design and Usability.I've worked with Web and Mobile applications, using custom and generic frameworks, like Angular and React, expecially to develop a more accessible, beautiful and performant apps.<br>
            After the University, I've continue working as Web Fullstack Developer, using <b>Angular 9+, Typescript, Java, Spring-boot and SASS.</b><br><br>
            After 3 years, I've decided to change my career and start to work as self-employed, boosting my skills as frontend developer, and working with a lot of new realities.<br><br>
             But, why I started my story with my first games and my passion for <b>Game development ?</b><br><br>
             Aside from my main job, my hobby is game development at <b>Unity</b>, and this involves also design, music production, and other skills rather than programming. In my free time I love to prototype games and 3D content for web apps.<br><br>
          </span>
          </span>
          <div class="personal-icons">
            <div class="personal-icons-item">
              <img class="image"
                   [src]="'assets//' + (loadedAssets$ | async)?.scienceIcon?.path"/>
              <span>Science passionate</span>
            </div>
            <div class="personal-icons-item">
              <img class="image"
                   [src]="'assets//' + (loadedAssets$ | async)?.catIcon?.path"/>
              <span>Cat(s) owner</span>
            </div>
            <div class="personal-icons-item">
              <img class="image"
                   [src]="'assets//' + (loadedAssets$ | async)?.beerIcon?.path"/>
              <span>Beer lover</span>
            </div>
            <div class="personal-icons-item">
              <img class="image"
                   [src]="'assets//' + (loadedAssets$ | async)?.mountainIcon?.path"/>
              <span>Mountain traveler</span>
            </div>
          </div>
        </div>
        <div id="education">
          <h2> Education </h2>
          <div class="education-item">
            <div class="image"
                 [style.background]="'url(assets/' + (loadedAssets$ | async)?.mugla?.path + ')'"></div>
            <br>
            <div class="education-item-title">
              Degree in Computer Engineering
            </div>
            <div class="education-item-subtitle">
              <app-link link="http://www.bilgisayar.mu.edu.tr/"
                        target="_blank">Computer Engineering at Mugla Sıtkı Kocman University
              </app-link>
              <p>4 years Degree in Engineering Department of Computer Engineering, Mugla Sıtkı Kocman University. Learning programming theory, algebra
                 and math.</p>
            </div>
            <div class="education-item-dates">
              2017 - 2021
            </div>
          </div>
          <div class="education-item">
            <div class="image"
                 [style.background]="'url(assets/' + (loadedAssets$ | async)?.mugla?.path + ')'"></div>
            <br>
            <div class="education-item-title">
              Education of B2 English Proficiency School of Foreign Languages
            </div>
            <div class="education-item-subtitle">
              <app-link link="http://www.mu.edu.tr/tr"
                        target="_blank">Mugla Sıtkı Kocman University
              </app-link>
              <p>The period of English education and knowing myself at university. GPA: 70 / 100</p>
            </div>
            <div class="education-item-dates">
              2016 - 2017
            </div>
          </div>
          <div class="education-item">
            <div class="image"
                 [style.background]="'url(assets/' + (loadedAssets$ | async)?.akinal?.path + ')'"></div>
            <br>
            <div class="education-item-title">
              High school diploma
            </div>
            <div class="education-item-subtitle">
              <app-link link="https://akinal.meb.k12.tr/"
                        target="_blank">Ayten Kemal Akınal Anatolian High School
              </app-link>
              <p>4 years diploma, first advanced level of mathematics and economic theory learned.</p>
            </div>
            <div class="education-item-dates">
              2012 - 2016
            </div>
          </div>
        </div>


        <div id="works">
          <h2> Experiences </h2>
          <div class="work-item">
            <div class="work-item-left">
              <div class="image"
                   [style.background]="'url(assets/' + (loadedAssets$ | async)?.addvalue?.path + ')'"></div>
            </div>
            <div class="work-item-right">
              <div class="work-item-title">
                Freelance Consultant
              </div>
              <div class="work-item-subtitle">
                <app-link link="https://www.addvalue.it/"
                          target="_blank">AddValue
                </app-link>
              </div>
              <div class="work-item-dates">
                NOV 2018 - PRESENT
              </div>
              <div class="work-item-description">
                Working as Angular <b>8+ Frontend Specialist</b>, focused on applications styling and development.
                Currently we are working on a <i>stateless web application</i> using Angular 8, wich dialogs with a
                Spring Boot
                server using websockets.<br>
              </div>
            </div>
          </div>
          <div class="work-item">
            <div class="work-item-left">
              <div class="image"
                   [style.background]="'url(assets/' + (loadedAssets$ | async)?.vas?.path + ')'"></div>
            </div>
            <div class="work-item-right">
              <div class="work-item-title">
                Web Frontend Developer, Unity Developer
              </div>
              <div class="work-item-subtitle">
                <app-link link="https://www.vas.it/"
                          target="_blank">VAS
                </app-link>
              </div>
              <div class="work-item-dates">
                OCT 2018 - JUN 2019
              </div>
              <div class="work-item-description">
                Working as <b>Frontend developer</b> on <i>React</i> based web applications and on advanced <i>WebGL</i>
                contents with <i>Unity3D</i>.
              </div>
            </div>
          </div>
          <div class="work-item">
            <div class="work-item-left">
              <div class="image"
                   [style.background]="'url(assets/' + (loadedAssets$ | async)?.slowmedia?.path + ')'"></div>
            </div>
            <div class="work-item-right">
              <div class="work-item-title">
                Freelance developer
              </div>
              <div class="work-item-subtitle">
                <app-link link="https://www.slowmedia.it/"
                          target="_blank">SLOWMEDIA
                </app-link>
              </div>
              <div class="work-item-dates">
                APR 2018 - JUN 2019
              </div>
              <div class="work-item-description">
                Website creation, wordpress custom theme development.
              </div>
            </div>
          </div>
          <div class="work-item">
            <div class="work-item-left">
              <div class="image"
                   [style.background]="'url(assets/' + (loadedAssets$ | async)?.eleads?.path + ')'"></div>
            </div>
            <div class="work-item-right">
              <div class="work-item-title">
                Freelance developer
              </div>
              <div class="work-item-subtitle">
                <app-link href="https://www.e-leads.it/"
                          target="_blank">ELEADS
                </app-link>
              </div>
              <div class="work-item-dates">
                JUL 2018 - AUG 2018
              </div>
              <div class="work-item-description">
                Angular developer, Frontend developer
              </div>
            </div>
          </div>
          <div class="work-item">
            <div class="work-item-left">
              <div class="image"
                   [style.background]="'url(assets/' + (loadedAssets$ | async)?.trueblue?.path + ')'"></div>
            </div>
            <div class="work-item-right">
              <div class="work-item-title">
                Fullstack Web Developer / Mobile Developer
              </div>
              <div class="work-item-subtitle">
                <app-link link="https://www.e-leads.it/"
                          target="_blank">ELEADS
                </app-link>
              </div>
              <div class="work-item-dates">
                FEB 2015 - MAR 2018
              </div>
              <div class="work-item-description">
                Web fullstack developer on custom CMS systems for Pharma industry. iOS Mobile developer.
              </div>
            </div>
          </div>
        </div>
        <div id="skills">
          <h2>My skills</h2>
          <h3>Development</h3>
          <div class="skills-container">
            <app-skills-item
              *ngFor="let skill of DEVELOPMENT_SKILLS; trackBy: trackByFn"
              [title]="skill.title"
              [description]="skill.description"
              [icon]="skill.icon"
            ></app-skills-item>
          </div>
          <br><br>
          <h3>Framework and Engines</h3>
          <div class="skills-container">
            <app-skills-item
              *ngFor="let skill of FRAMEWORK_SKILLS; trackBy: trackByFn"
              [title]="skill.title"
              [description]="skill.description"
              [icon]="skill.icon"
            ></app-skills-item>
          </div>
          <br><br>
          <h3>Tools</h3>
          <div class="skills-container">
            <app-skills-item
              *ngFor="let skill of TOOLS_SKILLS; trackBy: trackByFn"
              [title]="skill.title"
              [description]="skill.description"
              [icon]="skill.icon"
            ></app-skills-item>
          </div>
        </div>
        <div id="more">
          <h2>What to see next</h2>
          <span>
            There are a lot of things to see now, here there are some links to my social and projects:
          </span>
          <div class="more-links">
            <app-link [link]="'https://www.linkedin.com/in/SanderYaz'">LinkedIn</app-link>
            <app-link [link]="'https://www.youtube.com/SanderYaz'">YouTube</app-link>
            <app-link [link]="'https://github.com/SanderYaz'">GitHub</app-link>
            <app-link [link]="'https://gitlab.com/SanderYaz'">GitLab</app-link>
            <app-link [link]="'https://amazingsurprise.itch.io'">itch.io</app-link>
            <app-link [link]="'https://www.codingame.com/profile/a854de0c07f757bbde5aba79f11aecf21492014'">CodinGame
            </app-link>
            <app-link [link]="'https://soundcloud.com/SanderYaz'">SoundCloud</app-link>
            <app-link [link]="'https://twitter.com/SanderYaz'">Twitter</app-link>
            <app-link [link]="'https://dev.to/SanderYaz'">Dev.to</app-link>
            <app-link [link]="'https://dribbble.com/SanderYaz'">Dribbble</app-link>
            <app-link id="downloadCV"
                      [icon]="faDownload"
                      [link]="'assets/CV-2022.pdf'">
              Download Resume
            </app-link>
          </div>
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class WelcomeComponent {
  public data: any;
  loadedAssets$ = this.store$.pipe(select(selectLoadedAssets));

  isIntroVisible = true;
  isEducationVisible = false;
  isWorkVisible = false;

  faDownload = faDownload;
  faPhone = faPhone;
  faMail = faMailBulk;
  DEVELOPMENT_SKILLS = DEVELOPMENT_SKILLS;
  FRAMEWORK_SKILLS = FRAMEWORK_SKILLS;
  TOOLS_SKILLS = TOOLS_SKILLS;

  ids = ['intro', 'story', 'education', 'works', 'skills', 'more'];

  @ViewChild('welcomeRight', {static: true}) welcomeRight: ElementRef;
  private scrollTimeout: any;

  constructor(private store$: Store<any>) {}

  scrollTo(elementId) {
    this.welcomeRight.nativeElement.querySelector(`#${ elementId }`).scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }

  trackByFn(it, i) { return i; }

  onScroll($event: Event) {
    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(() => {
      this.afterScroll(($event.target as any).scrollTop);
    }, 100);
  }

  afterScroll(scrollTop: number) {
    this.ids.forEach(id => {
      const element = document.querySelector(`#${ id }`) as any;
      if ( !element ) {
        return;
      }
      if ( scrollTop >= element.offsetTop - 500 ) {
        document.querySelector(`.${ id }-anchor`).classList.add('active');
      } else {
        document.querySelector(`.${ id }-anchor`).classList.remove('active');
      }
    });
  }
}
