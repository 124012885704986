/** GLOBAL APP FILESYSTEM */
import {File, FILE_CATEGORY, FileSystem} from '@interfaces/interfaces';

export const FS_PATH_MAPPING = {
  ROOT: 'system',
  APPLICATION: 'applications',
  DESKTOP: 'desktop',
  UTILITIES: 'utilities',
  GAMES: 'games',
  OTHERS: 'others',
  INFO: 'info',
  SYSTEM: 'system',
  PROJECTS: 'projects',
  SOCIAL: 'social'
};

export const fs: FileSystem = new FileSystem(
  FS_PATH_MAPPING.ROOT,
  {
    system: FS_PATH_MAPPING.SYSTEM,
    info: FS_PATH_MAPPING.INFO,
    projects: FS_PATH_MAPPING.PROJECTS,
    social: FS_PATH_MAPPING.SOCIAL,
    games: FS_PATH_MAPPING.GAMES,
    applications: FS_PATH_MAPPING.APPLICATION,
    desktop: FS_PATH_MAPPING.DESKTOP,
    utilities: FS_PATH_MAPPING.UTILITIES,
    others: FS_PATH_MAPPING.OTHERS,
  }
);

export const FILES: File[] = [
  {
    properties: {
      name: 'Library Project Angular 14',
      alt: 'Library Project Angular 14 using Typescript',
      category: FILE_CATEGORY.LINK,
      data: {url: 'https://github.com/SanderYaz/NgRxLibrary'},
      icon: 'github',
      iconContrast: 'github'
    },
    fs: {
      paths: [fs.getPath(FS_PATH_MAPPING.PROJECTS)],
      root: fs.getPath(FS_PATH_MAPPING.PROJECTS)
    }
  },{
    properties: {
      name: 'GameLibrary',
      alt: 'GameLibrary Angular 14 using Typescript',
      category: FILE_CATEGORY.LINK,
      data: {url: 'https://github.com/SanderYaz/GameLibrary'},
      icon: 'github',
      iconContrast: 'github'
    },
    fs: {
      paths: [fs.getPath(FS_PATH_MAPPING.PROJECTS)],
      root: fs.getPath(FS_PATH_MAPPING.PROJECTS)
    }
  },{
    properties: {
      name: 'SY Messenger',
      alt: 'Chat Messenger using Android Java',
      category: FILE_CATEGORY.LINK,
      data: {url: 'https://github.com/SanderYaz/SYMessenger'},
      icon: 'github',
      iconContrast: 'github'
    },
    fs: {
      paths: [fs.getPath(FS_PATH_MAPPING.PROJECTS)],
      root: fs.getPath(FS_PATH_MAPPING.PROJECTS)
    }
  },{
    properties: {
      name: 'Bowling Unity Game',
      alt: 'BowlingUnity Game using Unity3D',
      category: FILE_CATEGORY.LINK,
      data: {url: 'https://github.com/SanderYaz/BowlingUnity'},
      icon: 'github',
      iconContrast: 'github'
    },
    fs: {
      paths: [fs.getPath(FS_PATH_MAPPING.PROJECTS)],
      root: fs.getPath(FS_PATH_MAPPING.PROJECTS)
    }
  },{
    properties: {
      name: 'Azazel Horror FPS Game',
      alt: 'Azazel Horror FPS Game using Unity',
      category: FILE_CATEGORY.LINK,
      data: {url: 'https://github.com/SanderYaz/Azazel'},
      icon: 'github',
      iconContrast: 'github'
    },
    fs: {
      paths: [fs.getPath(FS_PATH_MAPPING.PROJECTS),
        fs.getPath(FS_PATH_MAPPING.GAMES)],
      root: fs.getPath(FS_PATH_MAPPING.PROJECTS)
    }
  },
  {
    properties: {
      name: 'Itch.io',
      alt: 'My personal profile on itch.io',
      category: FILE_CATEGORY.LINK,
      data: {url: 'https://SanderYaz.itch.io/'},
      icon: 'itch',
      iconContrast: 'itch'
    },
    fs: {
      paths: [
        fs.getPath(FS_PATH_MAPPING.SOCIAL)
      ],
      root: fs.getPath(FS_PATH_MAPPING.SOCIAL)
    }
  },
  {
    properties: {
      name: 'DEV.to',
      alt: 'Checkout my DEV.to profile!',
      category: FILE_CATEGORY.LINK,
      data: {url: 'https://dev.to/SanderYaz'},
      icon: 'devTo',
      iconContrast: 'devTo'
    },
    fs: {
      paths: [fs.getPath(FS_PATH_MAPPING.SOCIAL)],
      root: fs.getPath(FS_PATH_MAPPING.SOCIAL)
    }
  },
  {
    properties: {
      name: 'LinkedIn',
      alt: 'Checkout my Linkedin profile!',
      category: FILE_CATEGORY.LINK,
      data: {url: 'https://www.linkedin.com/in/SanderYaz'},
      icon: 'linkedin',
      iconContrast: 'linkedin'
    },
    fs: {
      paths: [
        fs.getPath(FS_PATH_MAPPING.SOCIAL)],
      root: fs.getPath(FS_PATH_MAPPING.SOCIAL)
    }
  },
  {
    properties: {
      name: 'Twitter',
      alt: 'Checkout my Twitter profile!',
      category: FILE_CATEGORY.LINK,
      data: {url: 'https://twitter.com/SanderYaz'},
      icon: 'twitterIcon',
      iconContrast: 'twitterIcon'
    },
    fs: {
      paths: [
        fs.getPath(FS_PATH_MAPPING.SOCIAL)],
      root: fs.getPath(FS_PATH_MAPPING.SOCIAL)
    }
  },
  {
    properties: {
      name: 'Reddit',
      alt: 'Checkout my Reddit profile!',
      category: FILE_CATEGORY.LINK,
      data: {url: 'https://www.reddit.com/user/SanderYaz'},
      icon: 'redditIcon',
      iconContrast: 'redditIcon'
    },
    fs: {
      paths: [fs.getPath(FS_PATH_MAPPING.SOCIAL)],
      root: fs.getPath(FS_PATH_MAPPING.SOCIAL)
    }
  },
  {
    properties: {
      name: 'HackerNoon',
      alt: 'Checkout my HackerNoon profile!',
      category: FILE_CATEGORY.LINK,
      data: {url: 'https://hackernoon.com/u/SanderYaz'},
      icon: 'hackernoonIcon',
      iconContrast: 'hackernoonIcon'
    },
    fs: {
      paths: [fs.getPath(FS_PATH_MAPPING.SOCIAL)],
      root: fs.getPath(FS_PATH_MAPPING.SOCIAL)
    }
  },
  {
    properties: {
      name: FS_PATH_MAPPING.APPLICATION,
      alt: 'Applications folder',
      category: FILE_CATEGORY.FOLDER,
      icon: 'folderIcon',
      iconContrast: 'folderIcon'
    },
    fs: {
      paths: [
        fs.getPath(FS_PATH_MAPPING.ROOT),
        fs.getPath(FS_PATH_MAPPING.DESKTOP)
      ],
      root: fs.getPath(FS_PATH_MAPPING.APPLICATION)
    }
  },
  {
    properties: {
      name: FS_PATH_MAPPING.PROJECTS,
      alt: 'Projects folder',
      category: FILE_CATEGORY.FOLDER,
      icon: 'folderIcon',
      iconContrast: 'folderIcon'
    },
    fs: {
      paths: [fs.getPath(FS_PATH_MAPPING.ROOT), fs.getPath(FS_PATH_MAPPING.DESKTOP)],
      root: fs.getPath(FS_PATH_MAPPING.PROJECTS)
    }
  },
  {
    properties: {
      name: FS_PATH_MAPPING.GAMES,
      alt: 'Games folder',
      category: FILE_CATEGORY.FOLDER,
      icon: 'folderIcon',
      iconContrast: 'folderIcon'
    },
    fs: {
      paths: [fs.getPath(FS_PATH_MAPPING.ROOT), fs.getPath(FS_PATH_MAPPING.DESKTOP)],
      root: fs.getPath(FS_PATH_MAPPING.GAMES)
    }
  },
  {
    properties: {
      name: FS_PATH_MAPPING.SOCIAL,
      alt: 'Socials folder',
      category: FILE_CATEGORY.FOLDER,
      icon: 'folderIcon',
      iconContrast: 'folderIcon'
    },
    fs: {
      paths: [fs.getPath(FS_PATH_MAPPING.ROOT), fs.getPath(FS_PATH_MAPPING.DESKTOP)],
      root: fs.getPath(FS_PATH_MAPPING.SOCIAL)
    }
  }
];
